import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path:'/',
		redirect:'/manage'
	},
	{
		path:'/login',
		component:() => import('@/views/Login.vue'),
	},
	{
		path:'/protocol',
		component:() => import('@/views/other/protocol.vue'),
	},
	{
		path: '/register_b',
		component:() => import('@/views/other/Register.vue'),
	},
	{
		path: '/promotion_article',
		component:() => import('@/views/other/promotion_article.vue'),
	},
	{
		path: '/webview',
		component:() => import('@/views/other/webview.vue'),
	},
	{
		path: '/product_error_page',
		component:() => import('@/views/other/product_error_page'),
	},
	{
		path: '/manage',
		component:() => import('@/views/Manage.vue'),
		children:[
			{	
				path: 'agency_user',
				component: () => import('@/views/user/AgencyUserView.vue')
			},
			{
				path:'agency_product',
				component:()=>import('@/views/product/AgencyProductView.vue')
			},
			{
				path:'agency_product_card',
				component:()=>import('@/views/product/AgencyProductCardView.vue')
			},
			{
				path:'agency_product_for_agent',
				component:()=>import('@/views/product/AgencyProductForAgent.vue')
			},
			{
				path:'agency_card_for_agent',
				component:()=>import('@/views/product/AgencyCardForAgent.vue')
			},
			{
				path:'setting_short_website',
				component:()=>import('@/views/setting/ShortWebsiteView.vue')
			},
			{
				path:'record_agent_product_records',
				component:()=>import('@/views/records/AgentProductRecords.vue')
			},
			{
				path:'record_agent_card_records',
				component:()=>import('@/views/records/AgentCardRecords.vue')
			},
			{
				path:'record_agent_product_records_for',
				component:()=>import('@/views/records/AgentProductRecordsForAgent.vue')
			},
			{
				path:'record_agent_card_records_for',
				component:()=>import('@/views/records/AgentCardRecordsForAgent.vue')
			},
			{
				path:'yunma',
				component:()=>import('@/views/other/yunma/yunma.vue')
			},
			{
				path:'test',
				component:()=>import('@/views/other/test.vue')
			},
			{
				path:'link_check',
				component:()=>import('@/views/setting/LinkCheckView.vue')
			},
			{
				path:'generate_qrcode',
				component:()=>import('@/views/setting/GenerateQrcode.vue')
			},
			{	// 脚本主页,主要用来超控脚本
				path:'script_main',
				component:()=>import('@/views/setting/Script/ScriptMainView.vue')
			},
			{	// 脚本模板
				path:'script_template',
				component:()=>import('@/views/setting/Script/ScriptTemplateView.vue')
			},
			{	// 脚本订单
				path:'script_order',
				component:()=>import('@/views/setting/Script/ScriptOrderView.vue')
			},
			{	// 脚本创意
				path:'script_creative',
				component:()=>import('@/views/setting/Script/ScriptCreativeView.vue')
			}
		]
    },
]

const router = new VueRouter({
  routes
})
// router.beforeEach(({ to, from, next }) => {
//      document.title = '钱满屋'
// })
export default router
