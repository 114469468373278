import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios'

// 开始域名--------------------------------------------------------------
  //前缀的HTTPS路径
  const HTTP_URL = 'http://'
  const HTTPS_URL = 'https://'

  // 本地服务器
  const LOCAL_URL = {
    HOST:'localhost:9100',
    SUFFIX:'/',
    HTTP:HTTP_URL,
    WEBSOCKET:'ws://localhost:9100/'
  }
  //代理商域名
  const SERVER_URL_BFBZ = {
    HOST:'bfbz.cc',
    SUFFIX:'/api/',
    HTTP:HTTPS_URL,
    WEBSOCKET:'wss://bfbz.cc/ws'
  }
  // 域名整合方案
  const getAllUrl = URL => URL.HTTP + URL.HOST + URL.SUFFIX ;
// 域名结束---------------------------------------------------------------
import table_template_main_tempData from "./modules/table_template_main_tempData"

export default new Vuex.Store({
  state: {
	  global_base_url:{
		  main:getAllUrl(SERVER_URL_BFBZ),
          websocket_url:SERVER_URL_BFBZ.WEBSOCKET,
          url_object:SERVER_URL_BFBZ,
	  },
	product_types:{
      list:[],
      type_map_name:{},
      id:0,
      is_complete:false,
      promise:null
    },
    uv:{
      id:-1,  // 短链id号，例如：32
      is_show_dialog:false,
    },
    // 检查链接是否有效
    link_check:{
        bit_for_fresh:true
    }
  },
  getters: {

  },
  mutations: {
    // 这里payload填写短链id号，例如：32
    uv_change(state,payload){
      console.log("payload",payload);
      let is_payload_correct = payload || typeof payload == 'number' || payload >0 ;
      if(is_payload_correct){
        state.uv.id = payload ;
        state.uv.is_show_dialog = true ;
      }
    },
    close_dialog(state){
      state.uv.is_show_dialog = false;
    },
    // 更新数据
    update_product_types(state,payload){
      state.product_types.list = payload;
      state.product_types.is_complete = true ;
      state.product_types.list.forEach(item=>{
        state.product_types.type_map_name[item.type] = item.name;
      })
    }
  },
  actions: {
    async  init_or_fresh_product_types({state,commit}){
      // 1. 请求数据
      state.product_types.is_complete = false;
      const url  = state.global_base_url.main+"agency-product-type/list";
      state.product_types.promise = axios.get(url);
      const {data}  = await	state.product_types.promise;
      // 2. 刷新数据
      commit("update_product_types",data);
    },
    async update_product_types_byId({state,dispatch},payload){
      state.product_types.is_complete = false;
      let send_data = {
        id:payload.id,
        name:payload.name,
      };
      const url = state.global_base_url.main+"agency-product-type/update";
      const {data} = await axios({
        url:url,
        data:send_data,
        method:"PUT"
      })
      state.product_types.is_complete = true;
      dispatch("init_or_fresh_product_types");
      return true;
    }
  },
  modules: {
    table_template_main_tempData
  }
})
